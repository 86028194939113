a {
    text-decoration: none !important;
    color: inherit !important;
}

input {
    text-transform: capitalize;
}

*:focus {
    outline: 0 !important;
}

.Form-input {
    width: 100%;
}

.Form-input-container {
    padding-top: 10px;
    padding-bottom: 10px;
}

.Form-actions {
    margin-top: 15px;
    text-align: right;
}

.Form-action-btn {
    margin-left: 10px !important;
}

.MuiInputLabel-filled.MuiInputLabel-shrink, .MuiInputLabel-shrink {
    transform: translate(0px, 5px) scale(0.75) !important
}

.MuiInputLabel-filled {
    transform: translate(0px, 20px) scale(1) !important;
}

.Centered-container {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.react-daterange-picker input, .react-daterange-picker select, .react-daterange-picker__inputGroup {
    color: inherit;
    pointer-events: none;
}

.react-daterange-picker select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.react-daterange-picker__wrapper {
    border: none !important;
}

.react-daterange-picker__calendar {
    z-index: 999 !important;
}

.react-calendar {
    font-family: inherit !important;
    color: inherit !important;
    opacity: 1 !important;
    border: none !important;
}